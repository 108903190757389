import React from 'react';
import config from '../../config';
export default function SideFooter() {
  return (
    <footer>
      <ul className="items">
        {config.emailId && (
          <li>
            <h3>E-mail</h3>
            <a href={`mailto:${config.emailId}`}>{config.emailId}</a>
          </li>
        )}
        {config.phone && (
          <li>
            <h3>Téléphone</h3>
            <span>{config.phone}</span>
          </li>
        )}
        {config.address && (
          <li>
            <h3>Adresse</h3>
            <span>{config.address}</span>
          </li>
        )}

        {/* {config.legal && (
          <li>
            <span>{config.legal}</span>
          </li>
        )} */}

        <li>
          <h3>Ailleurs</h3>
          <ul className="icons">
            {config.socialLinks.map(social => {
              const { icon, name, username, url } = social;
              return (
                <li key={url}>
                  <a href={url} className={`icon ${icon}`}>
                    <span className="label">{username} sur {name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </footer>
  );
}
