import React from 'react';
import config from '../../config';
import SideFooter from './SideFooter';
// import ContactForm from './ContactForm';
import { Link } from 'gatsby';

export default function Contact() {
  return (
    <section>
      <header>

        <h2 id="nous-contacter">Nous contacter</h2>
        <SideFooter />
      </header>
      <div className="content">
        <p>Vous êtes intéressé·e par une formation ? Vous souhaitez obtenir des détails, ou encore concevoir une formation sur mesure avec nous ?</p>
        <h3>Contactez-nous !</h3>
        <p><a href={`mailto:${config.emailId}`} className="button primary large">Par e-mail : {config.emailId}</a></p>
        {config.phone && (<p><a href={`tel:${config.phone}`} className="button primary large">Par téléphone : {config.phone}</a></p>)}

        {/* <ContactForm /> */}

      </div></section>
  );
}
