import React from 'react';
import config from '../../config';

export default function Footer() {
  return (
    <div className="copyright">
      &copy; Uptime Formation. Design:
      <a href="https://html5up.net">HTML5 UP</a><br />
      {config.legal && (
            <span>{config.legal}</span>
        )}
        {config.formation && (
            <><br /><span>{config.formation}</span></>
        )}
    </div>
  );
}
