import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import About from '../components/About';
import Contact from '../components/Contact';
// import Header from '../components/Header';
// import SideFooter from '../components/SideFooter';

// import CatalogTable from '../components/CatalogTable';

import { Link } from 'gatsby';

const IndexPage = () => (
    <Layout>
        <div id="wrapper">
            {/* <section className="intro">
      <h1>Formations</h1>
      <div className="content">
      <span className="image fill" data-position="center">
      <img src={heroImage} alt="" />
      </span>
      </div>
    </section> */}

            {/* <section className="intro"> */}
            <section>
                {/* <span className="image fill" data-position="center">
          <img src={heroImage} alt="" />
        </span> */}
                <h1>Qui sommes-nous ?</h1>
                <About />
            </section>
            {/* <section> */}
            {/* <header>
          <h2 id="nous-contacter">Nous contacter</h2>
        </header>
        <ContactForm /> */}
            {/* <SideFooter /> */}
            {/* </section> */}

            <section>
                <div className="content">
                    <ul className="actions">
                        <li>
                            <Link to="/" className="button primary large">
                                Découvrir Uptime
                            </Link>
                        </li><br />
                        <li>
                            <Link to="/formations" className="button primary large">
                                Notre catalogue de formations
                            </Link>
                        </li><br />
                        <li>
                            <a href="https://supports.uptime-formation.fr/" className="button primary large">
                                Supports de formations
                            </a>
                        </li>
                    </ul>
                </div>
            </section>

            <Contact />

            <Footer />
        </div>
    </Layout >
);

export default IndexPage;
// Qui sommes-nous

// Elie Gavoty est actuellement formateur freelance en développement et pratiques DevOps. Après une licence d'informatique et un master recherche à l'université Paris-7 en Histoire et Philosophie des sciences, il a travaillé un an chez Sewan, opérateur télécom et hébergeur fournissant des services IT et des solutions d'infrastructure, en tant que développeur et administrateur système (DevOps). Il poursuit par ailleurs un projet de doctorat et est impliqué dans plusieurs projets d'hébergement associatif entre Paris et Strasbourg.

// Hadrien Pélissier s'intéresse à l'architecture informatique des systèmes depuis 10 ans,  et a travaillé en tant qu'ingénieur sécurité pour la sécurisation d'infrastructures informatique d'entreprises. Il assure actuellement des formations de DevOps et de sécurité. Il aime bien les projets associatifs autour des technologies, des médias et des innovations dans la gouvernance décentralisée.