import React from 'react';
import config from '../../config';
import { Link } from 'gatsby';

// import elie from '../assets/images/elie.jpg';
// import hadrien from '../assets/images/hadrien.jpg';

export default function About() {
    return (<div className="content">
        {/* <img src={elie} className="portrait" /> */}
        <h3 id="elie">Élie Gavoty</h3>
        <p>Elie Gavoty est formateur freelance en développement et pratiques DevOps. Après une licence d'informatique et un master recherche à l'université Paris-7 en Histoire et Philosophie des sciences, il a travaillé un an chez Sewan, opérateur télécom et hébergeur fournissant des services IT et des solutions d'infrastructure, en tant que développeur et administrateur système (DevOps). Il poursuit par ailleurs un projet de doctorat et est impliqué dans plusieurs projets d'hébergement associatif entre Paris et Strasbourg.</p>
        {/* <img src={hadrien} className="portrait" /> */}
        <h3 id="hadrien">Hadrien Pélissier</h3>
        <p>Hadrien Pélissier est passionné de sécurité informatique, de déploiement de systèmes et de réseaux et de programmation. Il a travaillé en tant qu'ingénieur sécurité au sein du <abbr rel="Security Operation Center">SOC</abbr> de Criteo et du <abbr rel="Computer Emergency/Security Incident Response Team">CERT/CSIRT</abbr> de Lexsi (maintenant Orange Cyberdéfense). Il anime des formations sur les technologies DevOps et la sécurité. Il s'intéresse au rôle politique des technologies, des médias et des innovations dans les modèles de coopératives et de gestion des communs.</p>
        <h3 id="alex">Alexandre Aubin</h3>
        <p>Alexandre Aubin est formateur depuis 2017 à Linux, Bash et Python et développeur sénior et contributeur à l'écosystème des logiciels libres. Il est le développeur principal de <a href="https://github.com/YunoHost/YunoHost">YunoHost</a>, système d'exploitation rendant l'auto-hébergement accessible.</p>

        <p>Contactez-nous pour obtenir plus de détails sur nos programmes, ou bien pour concevoir une formation sur mesure avec vous !</p>
    </div>
    );
}